<template>
  <footer class="pt-4 pt-md-5">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-4">
          <img class="mb-2" src="@/assets/logo.png" alt="" width="150px" />
          <small class="d-block mb-3 text-muted">&copy; 2024</small>
        </div>
        <div class="col-12 col-md-4">
          <ul class="list-unstyled text-small text-light">
            <li class="mb-1">
              <a
                class="link-secondary text-decoration-none"
                href="tel:0935318998"
              >
                <i class="fa-thin fa-mobile"></i> 093 531-89-98</a
              >
            </li>
            <li class="mb-1">
              <a
                class="link-secondary text-decoration-none"
                href="tel:0955318998"
              >
                <i class="fa-thin fa-mobile"></i> 095 531-89-98</a
              >
            </li>
            <li class="mb-1">
              <a
                class="link-secondary text-decoration-none"
                href="tel:0965318998"
              >
                <i class="fa-thin fa-mobile"></i> 096 531-89-98</a
              >
            </li>
          </ul>
        </div>
        <div class="col-12 col-md-4">
          <h5 class="text-light">
            <i class="fa-regular fa-headset me-1"></i>Підтримка
          </h5>
          <ul class="list-unstyled text-small">
            <li class="mb-1">
              <img width="20px" src="@/assets/telegram.png" />
              <a
                target="_blank"
                class="link-secondary text-decoration-none"
                href="https://t.me/HolderPosHelp_bot"
              >
                telegram 24/7</a
              >
            </li>
            <li class="mb-1">
              <a
                class="text-secondary text-decoration-none small"
                href="mailto:support@holderpos.com.ua"
                ><i class="fa-thin text-white fa-envelope me-1"></i
                >support@holderpos.com.ua</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<style>
footer {
  background-color: #343a40;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.5); /* Минимальная тень вниз */
}
</style>
