import { defineStore } from "pinia";
import { ref } from "vue";

export const useModalsStore = defineStore("ModalsStore", () => {
  let ModalStudy = ref({});
  let ModalVideLink = ref("");
  let ModalTitle = ref("");
  let ModalText = ref("");

  let ModalCall = ref({});
  return {
    ModalStudy,
    ModalVideLink,
    ModalTitle,
    ModalText,
    ModalCall,
  };
});
