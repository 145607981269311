<template>
  <ModalStudy ref="refModalStudy" />
  <ModalCall ref="refModalCall" />
  <header class="p-3 fixed-top">
    <VueHeader />
  </header>
  <router-view />
  <VueFooter />

  <button
    @click="M.ModalCall.show()"
    class="floating-button-sm d-block d-md-none"
  >
    <i class="fa-sharp fa-regular fa-beat fa-lg fa-phone-volume me-2"></i>
    Замовити дзвінок
  </button>
  <button @click="M.ModalCall.show()" class="floating-button d-none d-md-block">
    <i class="fa-sharp fa-regular fa-beat fa-lg fa-phone-volume me-2"></i>
    Замовити дзвінок
  </button>
</template>

<script setup>
import { ref, onMounted } from "vue";
import VueHeader from "@/components/MainHeader.vue";
import VueFooter from "@/components/MainFooter.vue";
import ModalStudy from "@/components/ModalStudy.vue";
import ModalCall from "@/components/ModalCall.vue";
import { Modal } from "bootstrap";
import { useModalsStore } from "@//ModalsStore";
const M = useModalsStore();
let refModalStudy = ref(null);
let refModalCall = ref(null);
onMounted(() => {
  M.ModalStudy = new Modal(refModalStudy.value.$el);
  M.ModalCall = new Modal(refModalCall.value.$el);
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  background: linear-gradient(135deg, #e2d4b7, #e2d4b7, #e2d4b7, #c1ae89);
  /* background-size: 100% 100%; */
  /* animation: subtleFlicker 3s ease-in-out infinite; */
}

header {
  background-color: #343a40;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Минимальная тень вниз */
}

:target::before {
  content: "";
  display: block;
  height: 80px; /* Выберите нужную высоту сдвига */
  margin: -80px 0 0;
}

@keyframes subtleFlicker {
  0%,
  100% {
    filter: brightness(1);
  }
  50% {
    filter: brightness(1.1);
  }
}

.text-bege {
  color: #e2d4b7 !important;
}

hr {
  border: none;
  height: 5px;
  background: linear-gradient(to right, #e2d4b7, #cab996, #e2d4b7);
  box-shadow: 0 10px 10px -2px rgba(0, 0, 0, 0.5);
  margin: 20px 0;
}

.modal-content {
  background-color: #e2d4b7;
}

.text-bege {
  color: #e2d4b7 !important;
}

.floating-button {
  position: fixed;
  bottom: 20px; /* Расстояние от нижнего края */
  right: 20px;
  background-color: #ffc107; /* Цвет кнопки */
  color: black; /* Цвет текста */
  opacity: 0.7;
  padding: 10px 20px; /* Отступы */
  border: none; /* Убираем границу */
  border-radius: 5px; /* Скругление углов */
  font-size: 16px; /* Размер текста */
  cursor: pointer; /* Указатель при наведении */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Тень для красоты */
  transition: transform 0.3s ease; /* Анимация при наведении */
  z-index: 1000; /* Убедитесь, что кнопка поверх всех элементов */
}

.floating-button-sm {
  position: fixed;
  bottom: 20px; /* Расстояние от нижнего края */
  right: 20px;
  background-color: #ffc107; /* Цвет кнопки */
  color: black; /* Цвет текста */
  opacity: 0.7;
  padding: 5px 10px; /* Отступы */
  border: none; /* Убираем границу */
  border-radius: 5px; /* Скругление углов */
  font-size: 10px; /* Размер текста */
  cursor: pointer; /* Указатель при наведении */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Тень для красоты */
  transition: transform 0.3s ease; /* Анимация при наведении */
  z-index: 1000; /* Убедитесь, что кнопка поверх всех элементов */
}

.floating-button:hover {
  transform: scale(1.1); /* Увеличение при наведении */
  background-color: #a77f04;
}

.btn-outline-bege {
  color: #e2d4b7; /* Цвет текста */
  border: 1px solid #e2d4b7; /* Цвет границы */
  background-color: transparent; /* Прозрачный фон */
  transition: all 0.3s ease; /* Плавная анимация */
}

.btn-outline-bege:hover {
  color: #fff; /* Цвет текста при наведении */
  background-color: #e2d4b7; /* Фон при наведении */
  border-color: #e2d4b7; /* Цвет границы при наведении */
}

.btn-outline-bege:focus,
.btn-outline-bege:active {
  color: #fff; /* Цвет текста при фокусе/нажатии */
  background-color: #d2c4a7; /* Затемнённый фон */
  border-color: #d2c4a7; /* Цвет границы */
  box-shadow: 0 0 0 0.25rem rgba(226, 212, 183, 0.5); /* Эффект фокуса */
}

body {
  background-color: #e2d4b7 !important;
}
</style>
