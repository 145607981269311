<template>
  <div
    class="modal fade"
    id="staticBackdrop"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">
            {{ M.ModalTitle }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="">
            <video
              class="rounded shadow"
              id="myVideo"
              width="100%"
              autoplay
              muted
              loop
              playsinline
              controls
            >
              <source :src="M.ModalVideLink" type="video/mp4" />
              Ваш браузер не підтримує відтворення відео.
            </video>
          </div>
          <div class="col-12 ps-2">
            <div class="container mt-2">
              <p class="mb-2 text-start" style="font-size: 0.9rem">
                {{ M.ModalText }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useModalsStore } from "@//ModalsStore";
const M = useModalsStore();
import { watch } from "vue";

watch(
  () => M.ModalVideLink,
  () => {
    const videoElement = document.getElementById("myVideo");
    if (videoElement) {
      videoElement.load();
    }
  }
);
</script>

<style scoped>
.modal-content {
  background-color: #e2d4b7;
}
</style>
