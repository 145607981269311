<template>
  <div class="container">
    <div
      class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start"
    >
      <div class="me-auto">
        <a
          href="/"
          class="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none"
        >
          <img class="mb-2 me-2" src="@/assets/logo.png" alt="" height="30px" />
        </a>
      </div>

      <button
        class="btn btn-outline-bege d-block d-lg-none"
        type="button"
        @click="LeftMenu.show()"
      >
        <i class="fa-regular fa-bars"></i>
      </button>
      <div class="text-end d-none d-lg-block">
        <ul
          class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0"
        >
          <li>
            <a href="#main" class="nav-link px-2 pointer">Головна</a>
          </li>
          <li>
            <a href="#listFutures" class="nav-link px-2">Можливості</a>
          </li>
          <li>
            <a href="#appKasa" class="nav-link px-2">Завантажити</a>
          </li>
          <li>
            <a href="#forwho" class="nav-link px-2">Для кого?</a>
          </li>
          <!-- 
          <li>
            <a href="#howitwork" class="nav-link px-2">Опис</a>
          </li> -->

          <li>
            <a href="#pricing" class="nav-link px-2">Вартість</a>
          </li>

          <li>
            <a href="#registration" class="btn btn-outline-bege btn-sm me-1">
              <i class="fa-regular fa-user-tie"></i> реєстрація
            </a>
          </li>

          <li>
            <a
              href="https://console.holderpos.com.ua"
              target="_blank"
              class="btn btn-outline-bege btn-sm"
            >
              <i class="fa-light fa-desktop"></i> консоль
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="offcanvasExample"
    ref="refLeftMenu"
    style="width: 50vw !important"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExampleLabel">Меню</h5>
      <button
        type="button "
        class="btn-close text-bege"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <div class="text-start">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <a href="#main" class="nav-link px-2 w-100 pointer">Головна</a>
          </li>
          <li class="list-group-item">
            <a href="#listFutures" class="nav-link w-100 px-2">Можливості</a>
          </li>
          <li class="list-group-item">
            <a href="#appKasa" class="nav-link w-100 px-2">Завантажити</a>
          </li>
          <li class="list-group-item">
            <a href="#forwho" class="nav-link w-100 px-2">Для кого?</a>
          </li>

          <!-- <li>
            <a href="#howitwork" class="nav-link px-2">Опис</a>
          </li> -->

          <li class="list-group-item">
            <a href="#pricing" class="nav-link w-100 px-2">Вартість</a>
          </li>

          <li class="list-group-item">
            <a
              href="#registration"
              class="btn btn-outline-bege w-100 btn-sm me-3"
            >
              <i class="fa-regular fa-user-tie"></i> реєстрація
            </a>
          </li>
          <li class="list-group-item">
            <a
              href="https://console.holderpos.com.ua"
              target="_blank"
              class="btn btn-outline-bege btn-sm w-100"
            >
              <i class="fa-light fa-desktop"></i> консоль
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { Offcanvas } from "bootstrap";

let refLeftMenu = ref(null);
let LeftMenu = ref({});
onMounted(() => {
  LeftMenu.value = new Offcanvas(refLeftMenu.value);
});
</script>
<style scoped>
.nav-link {
  position: relative;
  display: inline-block;
  /* color: white; */
  color: #e2d4b7;
  text-decoration: none;
  transition: color 0.3s ease; /* Плавное изменение цвета */
}

.list-group-item {
  background: none;
}

.nav-link:hover {
  color: #ffd700; /* Золотой цвет текста при наведении */
}

.nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #ffd700; /* Золотая линия подчеркивания */
  transition: width 0.3s ease; /* Плавное появление линии */
}

.nav-link:hover::after {
  width: 100%; /* Полностью заполняет ширину элемента при наведении */
}

.pointer {
  cursor: pointer;
}

.offcanvas {
  background-color: #343a40;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.5);
  /* width: 50%; */
}

.btn-outline-bege {
  color: #e2d4b7; /* Цвет текста */
  border: 1px solid #e2d4b7; /* Цвет границы */
  background-color: transparent; /* Прозрачный фон */
  transition: all 0.3s ease; /* Плавная анимация */
}

.btn-outline-bege:hover {
  color: #fff; /* Цвет текста при наведении */
  background-color: #e2d4b7; /* Фон при наведении */
  border-color: #e2d4b7; /* Цвет границы при наведении */
}

.btn-outline-bege:focus,
.btn-outline-bege:active {
  color: #fff; /* Цвет текста при фокусе/нажатии */
  background-color: #d2c4a7; /* Затемнённый фон */
  border-color: #d2c4a7; /* Цвет границы */
  box-shadow: 0 0 0 0.25rem rgba(226, 212, 183, 0.5); /* Эффект фокуса */
}
</style>
