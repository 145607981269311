<template>
  <div
    class="modal fade"
    id="modalcall"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="modalcall"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h1 class="modal-title fs-5" id="modalcall">Замовити дзвінок</h1>
          <button
            @click="M.ModalCall.hide()"
            type="button"
            class="btn-close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="!request">
            <div class="form-group text-start mt-2">
              <label>Ваше Імʼя</label>
              <input
                type="text"
                class="form-control"
                placeholder=""
                v-model="call.fio"
              />
            </div>
            <div class="form-group text-start">
              <label>Телефон</label>
              <input type="text" class="form-control" v-model="call.phone" />
            </div>
            <div class="form-group text-start">
              <label>
                <img width="20px" src="@/assets/telegram.png" /> Telegram</label
              >
              <input
                type="email"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder=""
                v-model="call.telegram"
              />
              <small class="form-text text-muted"> </small>
            </div>
            <button
              @click="sendCall"
              class="btn btn-outline-secondary mt-3 mb-2"
            >
              Відправити
            </button>
          </div>
        </div>
        <div v-if="request">
          <h5>Запит відпралено</h5>
          <p>Очікуйте найближчим часом з вами звʼяжется оператор</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useModalsStore } from "@//ModalsStore";
const M = useModalsStore();
import Swal from "sweetalert2";
import axios from "axios";

let call = ref({ phone: "+380", telegram: "@" });
let request = ref(false);

const sendCall = () => {
  if (!call.value.phone) {
    AlertInfo(call.value.phone + "Завопніть поле Телефон");
    return;
  }
  call.value.newcall = true;
  axios
    .post("https://registration.holderpos.com.ua/", call.value)
    .then(r => {
      if (r.data.status == "false") {
        console.log(r.data);
        AlertInfo(r.data.msg);
        call.value = { phone: "+380" };
        return;
      }
      if (r.data.status == "true") {
        request.value = true;
        //AlertInfo(r.data.msg);
        console.log(r.data);
      }
    })
    .catch(error => {
      // Обработка ошибок
      console.error("Ошибка:", error);
    });
};

const AlertInfo = msg => {
  Swal.fire({
    toast: true,
    position: "top-end",
    timer: 4000,
    text: msg,
    icon: "info",
    timerProgressBar: true,
    showConfirmButton: false,
  });
};
</script>

<style scoped>
.modal-content {
  background-color: #e2d4b7;
}
</style>
